import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from "gatsby";
import './work-vision.css';

import IntroductionLayout from "../../container/IntroductionLayout"
import ImageShower from "../../components/WorkVisionComponents/ImageShower"

function WorkVisionPage () {
  const imageData = useStaticQuery(graphql`
      query {
          newAwardImage: file(relativePath: { eq: "2017-new-award.jpg" }) {
              childImageSharp {
                  fixed(height: 700) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          innoBizImage: file(relativePath: { eq: "innobiz.jpg" }) {
              childImageSharp {
                  fixed(height: 700) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
          isoImage: file(relativePath: { eq: "iso9001-2020.jpg" }) {
              childImageSharp {
                  fixed(height: 700) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
      }
  `)

  return (
    <IntroductionLayout title="이력 사항">
      <ImageShower
        fixed={imageData.newAwardImage.childImageSharp.fixed}
        title="2017 신지식경영대상" />
      <ImageShower
        fixed={imageData.innoBizImage.childImageSharp.fixed}
        title="기술혁신형 중소기업 확인서(INNO-BIZ)" />
      <ImageShower
        classes="last-shower"
        fixed={imageData.isoImage.childImageSharp.fixed}
        title="품질경영시스템 인증서(ISO9001)" />
    </IntroductionLayout>
  )
}

export default WorkVisionPage